import React, { useEffect } from "react";
import $ from "jquery";
import storesData from "../shops/stores_data.json";
import { useLocation, useNavigate } from "react-router-dom";

document.addEventListener("DOMContentLoaded", function () {
  document.body.addEventListener("click", function (event) {
    if (
      event.target.tagName === "A" &&
      event.target.closest(".stores-list__content")
    ) {
      var shopName = event.target.textContent;
      shopsItemClickEvent(shopName);
    }
  });
});

function shopsItemClickEvent(shopName) {
  if (window._satellite && typeof window._satellite.track === 'function') {
    window._satellite.track("digitalcatalog", {
      event: "click_store",
      eventLabel: shopName,
    });
    console.log("window._satellite.track", "click_store", shopName);
  } else {
    console.log("window._satellite is not defined or track method is not available");
  }
}

const ShopsList = ({ activeContinent, activeCountry, activeCity }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const toggleStoreList = (selector) => {
    $(selector).hide();
    $(selector.replace("__content", "__title"))
      .off("click")
      .on("click", function (event) {
        event.stopPropagation();
        const text = $(this).text().trim();
        const $currentAccordionContent = $(this).next(".stores-list__content");
        const isChildAccordion = $(this).hasClass("additional");
        const isOpen = $currentAccordionContent.is(":visible");

        if (isOpen && isChildAccordion) {
          $currentAccordionContent.slideUp();
          $(this).parent(".stores-list__item").removeClass("is-open");
          $(this).find(".arrow-icon").removeClass("rotated");
        } else if (isOpen && !isChildAccordion) {

          $currentAccordionContent.slideUp();
          $(this).parent(".stores-list__item").removeClass("is-open");
          $(this).find(".arrow-icon").removeClass("rotated");
        
          if ($(".stores-list__item.is-open").length === 0) {
  
            navigate("/", { replace: true });
          }
        } else {
     
          let slugUrl =  buildSlugFromPath(text) ;
          if (slugUrl && location.pathname !== slugUrl) {
            navigate( slugUrl, { replace: true });
          }
          if (!isChildAccordion) {
            $(".stores-list__content").not($currentAccordionContent).slideUp();
            $(".stores-list__item")
              .not($(this).parent(".stores-list__item"))
              .removeClass("is-open");
            $(".arrow-icon")
              .not($(this).find(".arrow-icon"))
              .removeClass("rotated");
          }
          $currentAccordionContent.slideDown();
          $(this).parent(".stores-list__item").addClass("is-open");
          $(this).find(".arrow-icon").addClass("rotated");
        }
      });
  };

  const buildSlugFromPath = (text) => {
    let slug = "";
    Object.keys(storesData).forEach((continent) => {
      if (continent === text) {
        slug = storesData[continent].slug_url;
      } else {
        const countries = storesData[continent];
        if (
          countries.hasOwnProperty(text) &&
          countries[text].hasOwnProperty("slug_url")
        ) {
          const continentSlug = countries.slug_url;
          const countrySlug = countries[text].slug_url;
          slug = `${continentSlug}${countrySlug}`;
        }
      }
    });
    return slug;
  };

  const chunkStoresDataIntoColumns = () => {
    const storeKeys = Object.keys(storesData);
    const perColumn = Math.ceil(storeKeys.length / 2);
    return new Array(2).fill().map((_, i) => {
      return storeKeys.slice(i * perColumn, (i + 1) * perColumn);
    });
  };

  const columns = chunkStoresDataIntoColumns();

  const openAccordionsFromURL = () => {
    const currentPath = location.pathname;
    Object.keys(storesData).forEach((continent) => {
      if (currentPath.includes(storesData[continent].slug_url)) {
        $(`.stores-list__title.main-accordion:contains('${continent}')`)
          .next()
          .slideDown()
          .parent()
          .addClass("is-open");
        Object.keys(storesData[continent])
          .filter((key) => key !== "slug_url")
          .forEach((country) => {
            if (currentPath.includes(storesData[continent][country].slug_url)) {
              $(`.stores-list__title.additional:contains('${country}')`)
                .next()
                .slideDown()
                .parent()
                .addClass("is-open");
            }
          });
      }
    });
  };

  useEffect(() => {
    setTimeout(() => {
      openAccordionsFromURL();
    }, 500); 
  
    toggleStoreList(".stores-list__item .stores-list__content");
    toggleStoreList(".stores-list__item .stores-list__content .stores-list__item .stores-list__content");

  }, []);

  return (
    <div className="shopsList">
      {columns.map((column, columnIndex) => (
        <div key={columnIndex} className="stores__lists">
          {column.map((key) => {
            const item = storesData[key];
            return (
              <div key={key} className="stores__lists__column">
                <div className="stores-list__item">
                  <div className="stores-list__title main-accordion" onClick={(e) => e.stopPropagation()}>
                    {key}
                  </div>
                  <div className="stores-list__content">
                    {item.slug_accordion === "continent" ?
                      Object.keys(item)
                        .filter(subKey => subKey !== "slug_url" && subKey !== "slug_accordion")
                        .map((subKey) => (
                          <div key={subKey} className="stores__lists__column">
                            <div className="stores-list__item second">
                              <div className="stores-list__title additional" onClick={(e) => e.stopPropagation()}>
                                {subKey}
                              </div>
                              <div className="stores-list__content">
                                <ul>
                                  {Object.keys(item[subKey])
                                    .filter((city) => city !== "slug_url" && Array.isArray(item[subKey][city]))
                                    .map((city) => (
                                      item[subKey][city].map((store, index) => (
                                        store.URL ?
                                          <li key={index}>
                                            <a target="_blank" rel="noreferrer" href={store.URL}>
                                              {city}
                                            </a>
                                          </li> : null
                                      ))
                                    ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        ))
                    :
                      <ul>
                        {Object.keys(item)
                          .filter(subKey => subKey !== "slug_url" && subKey !== "slug_accordion" && Array.isArray(item[subKey]))
                          .map((city) => (
                            item[city].map((store, index) => (
                              store.URL ?
                                <li key={index}>
                                  <a target="_blank" rel="noreferrer" href={store.URL}>
                                    {city}
                                  </a>
                                </li> : null
                            ))
                          ))}
                      </ul>
                    }
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
  
};

export default ShopsList;
