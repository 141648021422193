import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import imageShop from './img/content/shop.jpg';
import imageShopMobile from './img/content/shop-mobile.jpg';
import ShopsList from './shared/shopsList';
import '../App.css';
import '../js/common.js';

function ShopsPage() {
  const { continent, country, city } = useParams();
  const [modalOpen, setModalOpen] = useState(null);
  const modalCookie = document.getElementById('cookie');
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

    useEffect(() => {
      var headerWhite = document.querySelector('.wrap-header');
      var footerWhite = document.querySelector('.wrap-footer');
      headerWhite.classList.remove('box-header');
      footerWhite.classList.add('cookie-shop-page');
    })

    const handleCookieClick = () => {
      localStorage.setItem('cookieModal', "open");
      setModalOpen("#cookie");
      if (modalCookie) {
        modalCookie.classList.add('is-open');
        document.body.classList.add('modal-expanded', 'cookie-expanded');
     }
    };
  
    useEffect(() => {
      const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  
      const openCookieCta = document.querySelectorAll('.cookie-cta');
      const eventHandler = (e) => {
        e.preventDefault(); 
        handleCookieClick();
      };
  
      openCookieCta.forEach(button => {
        if (isTouchDevice) {
          button.addEventListener('touchstart', eventHandler);
        } else {
          button.addEventListener('click', eventHandler);
        }
      });
  
      return () => {
        openCookieCta.forEach(button => {
          if (isTouchDevice) {
            button.removeEventListener('touchstart', eventHandler);
          } else {
            button.removeEventListener('click', eventHandler);
          }
        });
      };
    }, []);

  return (
    <div className="wrapper wrapper--page wrapper--page-stores">
      <div className="wrap">
        <div className="container">
          <div className="stores">
            <div className="stores__col stores__col--left">
              <picture className="stores__img">
                <img src={imageShop} width={649} height={694} alt="" />
              </picture>
              <picture className="stores__img mobile">
                <img src={imageShopMobile} width={375} height={170} alt="" />
              </picture>
            </div>
            <div className="stores__col stores__col--right">
            <h2>Select your preferred boutique</h2>
            <ShopsList activeContinent={continent} activeCountry={country} activeCity={city} />
            <footer className="wrap-footer mobile-only">
              <div className="footer">
                <button
                  className="footer__link js-open-modal cookie-cta"
                  data-modal="#cookie"
                  onClick={handleCookieClick}
                >
               {t('COOKIE_POLICY_LABEL')}
                </button>
              </div>
            </footer>
          </div>
          </div>
        </div>
      </div>
      <div className="overflow-bg" />
    </div>
  );
}

export default ShopsPage;
