import React from 'react';
import logo from '../img/logo.svg';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        const modalCookie = document.querySelector('#cookie');
        const cookieModal = localStorage.getItem('cookieModal');
    
        if (cookieModal === "open") {
            document.body.classList.remove('cookie-expanded');
            document.documentElement.classList.remove('cookie-expanded');
            if (modalCookie) modalCookie.classList.remove('is-open');
            localStorage.setItem('cookieModal', 'close');
        } else {
            navigate(-1);
        }
    };

  return (

    <div>
        <header className="wrap-header">
        <div className="header">
            <div className="header__actions header-actions header-actions--left">
            <div className="header-actions__item header-actions__item--go-back">
                    <button className="header-actions__btn header-actions__btn--go-back close-btn"
                            type="button"
                            aria-label="BACK"
                            onClick={handleGoBack}>
                        <i className="icon-close" />
                    </button>
                </div>
            </div>
        <a href="/" className="header__logo logo">
            <picture className="logo__img">
            <img src={logo} width={46} height={25} alt="" />
            </picture>
        </a>
        <div className="header__actions header-actions header-actions--right">
      
        </div>
        </div>
        </header>

        <div className="overflow-bg"></div>
    </div>
    );
};

export default Header;