import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import ShopsPage from './components/shopsPage';
import Footer from './components/shared/footer';
import Header from './components/shared/header';
import { initReactI18next } from 'react-i18next';  // Import initReactI18next
import i18n from './components/translate/i18n';

// Initialize react-i18next
i18n.use(initReactI18next).init();

function App() {
  
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<ShopsPage />} />
          <Route path="/:continent" element={<ShopsPage />} />
          <Route path="/:continent/:country" element={<ShopsPage />} />
          <Route path="/:continent/:country/:city" element={<ShopsPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;