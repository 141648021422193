import React, { useState } from 'react';
import { useEffect } from 'react';
import logoBig from '../img/logo-big.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CookiesEn from './cookies/cookiesEn';

const Footer = () => {
  const navigate = useNavigate(); 
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [modalOpen, setModalOpen] = useState(null);
  const modalCookie = document.getElementById('cookie');

  //window.innerWidth > 1100 ? 'vertical' : 'horizontal';

  useEffect(() => {
    
    const handleResize = () => {
      if (window.innerWidth > 1100) {
        localStorage.setItem('deviceDetected', 'desktop');
      } else {
        localStorage.setItem('deviceDetected', 'mobile');
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const handleCookieClick = () => {
    localStorage.setItem('cookieModal', "open");
    setModalOpen("#cookie");
    if (modalCookie) {
      modalCookie.classList.add('is-open');
      document.body.classList.add('modal-expanded', 'cookie-expanded');
   }
  };

  useEffect(() => {
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

    const openCookieCta = document.querySelectorAll('.cookie-cta');
    const eventHandler = (e) => {
      e.preventDefault(); 
      handleCookieClick();
    };

    openCookieCta.forEach(button => {
      if (isTouchDevice) {
        button.addEventListener('touchstart', eventHandler);
      } else {
        button.addEventListener('click', eventHandler);
      }
    });

    return () => {
      openCookieCta.forEach(button => {
        if (isTouchDevice) {
          button.removeEventListener('touchstart', eventHandler);
        } else {
          button.removeEventListener('click', eventHandler);
        }
      });
    };
  }, []);

  return (
    <div className="footer-container">
      <footer className="wrap-footer desktop-footer">
        <div className="footer">
          <button
            className="footer__link js-open-modal cookie-cta"
            data-modal="#cookie"
            onClick={handleCookieClick}
          >
           {t('COOKIE_POLICY_LABEL')}
          </button>
        </div>
      </footer>
      <i>
        <div className="modal modal--cookie" id="cookie" aria-hidden="true">
          <div className="modal-page">
            <h2>{t('COOKIE_POLICY_LABEL')}</h2>
            {currentLanguage === 'en' && <div><CookiesEn/></div>}
          </div>
        </div>
      </i>
      <div className="preload">
        <picture className="preload__cta-img">
          <img src={logoBig} width={160} height={89}  alt="" />
        </picture>
      </div>
        <div className="overflow-bg"></div>
    </div>
  );
};

export default Footer;