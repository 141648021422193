import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css'; 

$(function() {

    function check_webp_feature(feature, callback) {
        var kTestImages = {
            lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
            lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
            alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
            animation: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
        };
        var img = new Image();
        img.onload = function () {
            var result = (img.width > 0) && (img.height > 0); 
            callback(feature, result);
        };
        img.onerror = function () {
            callback(feature, false);
        };
        img.src = "data:image/webp;base64," + kTestImages[feature];
    }
    check_webp_feature('lossy', function (feature, isSupported) {
        if (isSupported) {
            document.querySelector('body').classList.add('webp')  // webp is supported, 
            // you can cache the result here if you want
        }
    });
    
      if ($('.js-open-modal').length) {
          $('.js-open-modal').on('click touchend', function(event) {
              event.preventDefault();
              var modal = $(this).data('modal');
              $(modal).addClass('is-open');
              $(modal).attr('aria-hidden', 'false');
              $('body, html').addClass('modal-expanded' + modal.replace(/#/, ' ') + '-expanded');
          });
  
          $('.close-btn, .overflow-bg, input[type=submit]').on('click touchend', function(event) {
              var modal = $('.js-open-modal').data('modal');
              $('.modal, .modal-menu, .wrap-menu').removeClass('is-open');
              $('body, html').removeClass('modal-expanded' + modal.replace(/#/, ' ') + '-expanded');
              $(modal).attr('aria-hidden', 'true');
          });
      }
  
    $('a.js-go-page').click(function(event) {
        event.preventDefault();
        var url = $(this).attr('href');
        $('html').addClass('is-loading');
        setTimeout(function() {
            window.location.href = url;
        }, 1000);
    });

    /*
    var resultSlider = new Swiper(".result-slider", {
        direction: getDirection(),
        slidesPerView: 1,
        spaceBetween: 20,
        freeMode: true,
        mousewheel: true,
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
          clickable: true,
        },
        on: {
            resize: function () {
                resultSlider.changeDirection(getDirection());
            },
        },
        breakpoints: {
            1100: {
              pagination: false,
            },
          },
      });
      
      function getDirection() {
        const deviceType = localStorage.getItem('deviceDetected');
        return deviceType === 'desktop' ? 'vertical' : 'horizontal';
      }
      */

      $('.stores-list__item .stores-list__content').hide();
      $('.stores-list__item .stores-list__title').click(function(){
        $(this).next('.stores-list__content').slideToggle();
        $(this).parent('.stores-list__item').toggleClass('is-open');
      });
});